export default {
  admin_noDataForSelectionParametars: 'Nema podataka za odabrane parametre',
  betslip_betslip: 'Listić',
  betslip_maxBetAmount: 'Maksimalni ulog je',
  betslip_maxLengthIs: 'Maksimalna duljina je',
  betslip_maxWinLimit: 'Maksimalni limit dobitka',
  betslip_minLengthIs: 'Minimalna duljina je',
  betslip_stake: 'Ulog',
  betslip_startBettingMessage: 'Odaberite okladu klikom na željenu kvotu',
  betslip_ticketResolving: 'Obrada listića u tijeku',
  betslip_totalCombinations: 'Ukupno kombinacija',
  betslip_totalStake: 'Ukupni ulog',
  betslip_haveOnBetslip: 'Već postoji na listiću',
  betslip_maxBetCountIs: 'Maksimalan broj oklada je',
  betslip_oddsHaveChangedFrom: 'Kvote su promijenjene od',
  betslip_acceptAndPayin: 'Prihvati i uplati',
  betslip_oddsHaveChanged: 'Kvote su promijenjene',
  betslip_ticketAccepted: 'Listić prihvaćen',
  betslip_balanceWarning: 'Nemate dovoljno raspoloživih sredstava.',
  betslip_maxTicketAmount: 'Maksimalni ulog',
  betslip_minBetAmount: 'Minimalni ulog',
  betslip_minTicketAmount: 'Minimalni ulog',
  betslip_stakePerBet: 'Ulog po okladi',
  betslip_toReturn: 'Mogući dobitak',
  betslip_invalidDrawType: 'Nevažeći tip izvlačenja',
  betslip_invalidOutcomeNumbers: 'Nevažeći odigrani brojevi za odabrani tip ishoda',
  betslip_invalidStandardBet: 'Standardna oklada je nevažeća',
  betslip_missingRequestUuid: 'Nedostaje UUID zahtjeva',
  betslip_numbersRange: 'Odabrani brojevi moraju biti unutar raspona turnira',
  betslip_sameDrumBonusBall: 'Ne možete odabrati bonus kuglicu za isti bubanj',
  betslip_outcomeIsAlready: 'Ishod već postoji na listiću',
  game_NumbersBetting: 'Loto klađenje',
  general_accept: 'Prihvati',
  general_all: 'Sve',
  general_bonus: 'Bonus',
  general_cancel: 'Poništi',
  general_check: 'Provjeri',
  general_close: 'Zatvori',
  general_combo: 'Kombinirani',
  general_country: 'Država',
  general_currentlyUnavailable: 'Klađenje na ovaj događaj trenutno nije dostupno',
  general_deleteShort: 'Brisanje',
  general_error: 'Pogreška',
  general_event: 'Runda',
  general_invalidTicketId: 'ID listića nije valjan',
  general_lastTickets: 'Posljednji listići',
  general_lost: 'Gubitni',
  general_market: 'Oklada',
  general_maxWinShort: 'Maks. dobitak',
  general_number: 'Broj',
  general_numbers: 'Brojevi',
  general_odd: 'Kvota',
  general_open: 'Otvoren',
  general_payIn: 'Uplata',
  general_payinTax: 'Porez na uplatu',
  general_payout: 'Isplata',
  general_payoutAmount: 'Iznos isplate',
  general_payoutTax: 'Porez na isplatu',
  general_pick: 'Odaberite',
  general_possiblePayoutTax: 'Mogući porez na isplatu',
  general_possibleWin: 'Mogući dobitak',
  general_requiredField: 'Obvezno polje',
  general_results: 'Rezultati',
  general_search: 'Pretraži',
  general_selection: 'Odabir',
  general_signIn: 'Prijavite se',
  general_single: 'Single',
  general_ticket: 'Listić',
  general_ticketCheck: 'Provjera listića',
  general_time: 'Vrijeme',
  general_totalOdds: 'Ukupna kvota',
  general_winnings: 'Dobitak',
  general_won: 'Dobitni',
  general_today: 'Danas',
  general_clear: 'Pobriši',
  general_edit: 'Uredi',
  general_reset: 'Resetiraj',
  general_save: 'Spremi',
  general_selectAll: 'Odaberi sve',
  general_totalTickets: 'Ukupno listića',
  general_pleaseWait: 'Molimo pričekajte',
  general_add: 'Dodaj',
  general_ticketCode: 'Kod listića',
  general_updateBet: 'Ažuriraj',
  general_payedout: 'Isplata',
  general_random: 'Slučajni odabir',
  general_addBet: 'Dodaj okladu',
  general_more: 'Više',
  general_info: 'Info',
  general_successs: 'Uspješno',
  general_categories: 'Kategorije',
  general_others: 'Ostalo',
  general_popular: 'Popularno',
  general_success: 'Uspješno',
  general_ticketId: 'ID listića',
  general_all_countries: 'Sve države',
  general_popular_lottery: 'Popularne lutrije',
  general_tournamentCancelled: 'Turnir otkazan',
  general_in_play: 'U igri',
  general_warning: 'Upozorenje',
  general_clearAll: 'Izbriši sve',
  general_refresh: 'Osvježi',
  general_sortByCategories: 'Sortiraj po kategorijama',
  general_sortByTime: 'Sortiraj po vremenu',
  general_translations: 'Prijevodi',
  general_to: 'do',
  general_betID: 'ID oklade',
  general_eventID: 'ID runde',
  general_outcomeID: 'ID ishoda',
  general_betslip: 'Listić',
  general_bonus_balls: 'Bonus kuglice',
  general_pay_in: 'Uplata',
  general_stake_per_bet: 'Ulog',
  general_back: 'Natrag',
  general_bet: 'Oklada',
  general_match_locked: 'Izvlačenje zaključano',
  general_name: 'Naziv',
  general_offer: 'Ponuda',
  general_selectAnOption: 'Odaberi opciju',
  general_selectionContent: 'Odaberi sadržaj',
  general_type: 'Tip',
  general_noDataAvailable: 'Nema podataka za odabrane parametre!',
  general_oddsMgmt: 'Upravljanje kvotama',
  general_quickPick: 'Brzi odabir',
  general_internalError: 'Interna pogreška',
  general_status: 'Status',
  general_id: 'ID',
  general_deleteLockedBets: 'Izbriši zaključane oklade?',
  general_acceptAndProceed: 'Prihvati i nastavi',
  general_min_payment_per_ticket_rule: 'Minimalna isplata po listiću je {value}.',
  general_max_payment_per_ticket_rule: 'Maksimalna isplata po listiću je {value}.',
  general_max_bet_amount_rule: 'Maksimalan iznos oklade je {value}.',
  general_min_bet_amount_rule: 'Minimalan iznos oklade je {value}.',
  general_noInternet: 'Izgleda da ste izvan mreže. Molimo provjerite internet konekciju.',
  general_application: 'Aplikacija',
  general_channel: 'Kanal',
  general_category: 'Kategorija',
  general_tournament: 'Turnir',
  general_player: 'Igrač',
  general_operator: 'Operator',
  general_betshop: 'Poslovnica',
  general_hour: 'Sat',
  general_week: 'Tjedan',
  general_month: 'Mjesec',
  general_maxPaymentAmount: 'Maksimalni iznos uplate.',
  general_maxTicketCount: 'Maksimalni broj listića.',
  general_maxSameNumbersSelection: 'Maksimalni broj oklada sa istim brojevima',
  general_futureBets: 'Future oklade',
  general_future_bet: 'Future oklada',
  general_stake_with_future_bets: 'Ulog sa svim future okladama',
  nb_bonusBall: 'Bonus kuglica',
  shop_betIsNotFound: 'Oklada nije pronađena',
  shop_outcomeIsNotInOffer: 'Ishod nije u ponudi',
  shop_matchWithId: 'Runda ID',
  shop_notfoundAlreadyStarted: 'Nije pronađena ili je već počela',
  shop_allEventsStarted: 'Sve rudne su već počele',
  shop_webCodeInvalidOrExpired: 'Web kod je nevažeći ili istekao',
  shop_nextEventStartingIn: 'Sljedeća runda počinje za',
  shop_newTicket: 'Novi listić',
  shop_print: 'Ispiši',
  shop_results: 'Rezulati',
  shop_random: 'Random',
  shop_fullOffer: 'Puna ponuda',
  shop_resetTicket: 'Poništi listić',
  shop_ticketType: 'Vrsta listića',
  shop_offerResultsPrint: 'Ponuda/Rezultati ispis',
  shop_printPreview: 'Pregled ispisa',
  shop_offerSelect: 'Odabir ponude za ispis',
  shop_resultsSelect: 'Odabir rezultata za ispis',
  shop_bettingDisabled: 'Klađenje onemogućeno',
  shop_printOffer: 'Ispis ponude',
  shop_resultsOffer: 'Ispis rezultata',
  shop_removeDuplicateNumbers: 'Ukloni duple brojeve',
  shop_printPreviewIsGenerating: 'Molimo pričekajte. Pregled ispisa se generira',
  shop_sameLocationPayoutRestriction: 'Listić može biti isplaćen samo u poslovnici u kojoj je odigran',
  shop_unknownTicketCheck: 'Nepoznat listić',
  shop_addToBetslip: 'Dodaj na listić',
  shop_removeBallsSelection: 'Ukloni odabrane kuglice',
  general_system: 'Sistem',
};
